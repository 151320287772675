<script>
import get from 'lodash/get';
import { MuiAlgoliaSelect, MuiModal } from '@emobg/motion-ui/v1';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import algoliaVehiclesFilter from '../algoliaVehiclesFilter';
export default {
  components: {
    MuiModal,
    MuiAlgoliaSelect,
    CancelButton,
  },
  props: {
    vehicleModelUuid: {
      type: String,
      required: true,
    },
    salesOrderUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      vehicleUuid: undefined,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleetInvoicing.salesOrders, {
      salesOrderStatus: state => get(state, 'STATUS'),
      errorOrderError: state => state.error,
    }),
    vehiclesFilter() {
      return algoliaVehiclesFilter(this.vehicleModelUuid);
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.modalConfig = {
      title: 'Add vehicle',
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.salesOrders, [
      'postSalesOrderAddVehicle',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async addVehicle() {
      await this.postSalesOrderAddVehicle({
        salesOrderUuid: this.salesOrderUuid,
        vehicleUuid: this.vehicleUuid,
      });
      if (!this.salesOrderStatus.ERROR) {
        this.notify({ message: 'Vehicle added successfully' });
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <MuiModal
    v-bind="modalConfig"
    is-open
    @modal-closed="$emit('close-modal')"
  >
    <div slot="body">
      <div>
        Only vehicles that are not assigned to any sales order are displayed.
      </div>
      <MuiAlgoliaSelect
        v-model="vehicleUuid"
        name="sales-order-vehicles"
        :index="ALGOLIA_INDEXES.fpVehicles"
        :title="result => result.license_plate"
        :filters="vehiclesFilter"
        class="w-100 mt-4"
        label="Select a vehicle*"
        placeholder="Select a vehicle"
        path-value="uuid"
        data-test-id="vehicles-list"
      />
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('close-modal')"
      />
      <ui-button
        :disabled="!vehicleUuid"
        :loading="salesOrderStatus.LOADING"
        narrow
        data-test-id="save-button"
        @clickbutton="addVehicle"
      >
        Add
      </ui-button>
    </div>
  </MuiModal>
</template>
