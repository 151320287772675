var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ContractsView container-fluid" }, [
    _c("h1", { staticClass: "mb-2" }, [_vm._v(" Contracts list ")]),
    _c(
      "div",
      [
        !_vm.isReloading
          ? _c(
              "ui-card",
              {
                attrs: {
                  header: "Dedicated fleet contracts",
                  description:
                    "Select the vehicles, the service agreement and the service fees of your sales order.",
                },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "header-action" }, slot: "header-action" },
                  [
                    _c(
                      "ui-button",
                      { on: { clickbutton: _vm.openContractForm } },
                      [_vm._v(" Create new contract ")]
                    ),
                  ],
                  1
                ),
                _c("MuiAlgoliaList", {
                  ref: "contractTable",
                  attrs: {
                    "table-config": _vm.contractsConfig,
                    filters: _vm.filterByCompany(_vm.companyUuid),
                    "item-actions": _vm.contractActions,
                    index: _vm.ALGOLIA_INDEXES.fpContracts,
                    "empty-message": _vm.getNoResultsLabel("contracts"),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.isContractFormOpen
          ? _c("ContractForm", {
              key: _vm.currentContractUuid,
              attrs: {
                "contract-uuid": _vm.currentContractUuid,
                "company-uuid": _vm.companyUuid,
              },
              on: {
                closeModal: _vm.closeContractForm,
                success: _vm.closeContractForm,
                "refresh-table": _vm.refreshTable,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }