var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-form",
    { on: { submitform: _vm.onSubmit } },
    [
      _c("MuiValidationWrapper", {
        ref: "validationWrapper",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ areAllValid }) {
              return [
                _c(
                  "GenericModalComponent",
                  _vm._b(
                    {
                      attrs: {
                        header: { title: _vm.modalTitle, isClosable: true },
                        size: _vm.SIZES.medium,
                        "data-test-id": "invoice-plan-modal",
                      },
                      on: { "modal-closed": _vm.closeModal },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "InvoicePlanFormComponent" },
                                  [
                                    _vm.formStatusIsLoading
                                      ? _c("ui-loader", {
                                          attrs: {
                                            relative: "",
                                            "data-test-id": "loader",
                                          },
                                        })
                                      : _c("section", [
                                          _c(
                                            "div",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12 mb-4" },
                                                [
                                                  _c("h3", [
                                                    _vm._v("Invoice info"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "LabelWithTooltip",
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label: "Invoice plan*",
                                                        tooltip:
                                                          "A serie suffix defining the final serie where the invoices will be created.\n                  Example: invoice plan name QWER will generate the serie\n                  `INV19/**QWER**-XXXX` (being XXXX the invoice number).",
                                                      },
                                                    },
                                                    [
                                                      _c("MuiInputText", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate.blur",
                                                            value: {
                                                              isRequired: true,
                                                            },
                                                            expression:
                                                              "{\n                        isRequired: true,\n                      }",
                                                            modifiers: {
                                                              blur: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          "data-test-id":
                                                            "invoice-plan-modal-billing-reference",
                                                          name: "billingReference",
                                                          placeholder:
                                                            "Invoice plan",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs
                                                              .billingReference,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs,
                                                              "billingReference",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.billingReference",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "LabelWithTooltip",
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label:
                                                          "First invoice date",
                                                        tooltip:
                                                          "Date when the first invoice will be issued.",
                                                      },
                                                    },
                                                    [
                                                      _c("ui-datetimepicker", {
                                                        attrs: {
                                                          size: _vm.SIZES.small,
                                                          skiptime: "",
                                                          "data-test-id":
                                                            "invoice-plan-modal-invoice-date",
                                                        },
                                                        domProps: {
                                                          date: _vm.inputs
                                                            .firstInvoiceDate,
                                                        },
                                                        on: {
                                                          datechanged:
                                                            _vm.onDateChange,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "LabelWithTooltip",
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label: "Invoice cycle*",
                                                        tooltip:
                                                          "Recurrence of the invoices.",
                                                      },
                                                    },
                                                    [
                                                      _c("MuiSelect", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value: {
                                                              isRequired: true,
                                                            },
                                                            expression:
                                                              "{\n                        isRequired: true,\n                      }",
                                                          },
                                                        ],
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          options: _vm.periods,
                                                          "option-label":
                                                            "name",
                                                          "option-value":
                                                            "code",
                                                          "data-test-id":
                                                            "invoice-plan-modal-period",
                                                          name: "period",
                                                          placeholder:
                                                            "Select a period",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs.period,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs,
                                                              "period",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.period",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "LabelWithTooltip",
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label: "NetD (days)*",
                                                        tooltip:
                                                          "Defines the amount of days the customer will have to pay the\n                  invoices, starting from the issue date.",
                                                      },
                                                    },
                                                    [
                                                      _c("MuiSelect", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value: {
                                                              isRequired: true,
                                                            },
                                                            expression:
                                                              "{\n                        isRequired: true,\n                      }",
                                                          },
                                                        ],
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          options:
                                                            _vm.netDaysList,
                                                          placeholder:
                                                            "Select days",
                                                          "option-label":
                                                            "netD",
                                                          "option-value":
                                                            "netD",
                                                          "data-test-id":
                                                            "invoice-plan-modal-netdays",
                                                          name: "netDays",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs.netDays,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs,
                                                              "netDays",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.netDays",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "LabelWithTooltip",
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label:
                                                          "Purchase order number (optional)",
                                                        tooltip:
                                                          "Number provided by the customer to relate the invoice with their purchase order.",
                                                      },
                                                    },
                                                    [
                                                      _c("MuiInputText", {
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          name: "purchaseOrderNumber",
                                                          "data-test-id":
                                                            "invoice-plan-modal-purchase-order-number",
                                                          placeholder:
                                                            "Purchase order number",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs
                                                              .purchaseOrderNumber,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs,
                                                              "purchaseOrderNumber",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.purchaseOrderNumber",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "LabelWithTooltip",
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label:
                                                          "Invoice footer (optional)",
                                                        tooltip:
                                                          "Invoice footer for the invoice.",
                                                      },
                                                    },
                                                    [
                                                      _c("MuiTextarea", {
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          name: "footer",
                                                          rows: "1",
                                                          "data-test-id":
                                                            "invoice-plan-modal-footer",
                                                          placeholder:
                                                            "Write invoice footer",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs.footer,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs,
                                                              "footer",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.footer",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-12 mb-4" },
                                                [
                                                  _c("h3", [
                                                    _vm._v("Customer info"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "LabelWithTooltip",
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label: "Customer name*",
                                                        tooltip:
                                                          "Customer details for the invoice.",
                                                      },
                                                    },
                                                    [
                                                      _c("MuiInputText", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate.blur",
                                                            value: {
                                                              isRequired: true,
                                                            },
                                                            expression:
                                                              "{\n                        isRequired: true,\n                      }",
                                                            modifiers: {
                                                              blur: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          placeholder:
                                                            "Customer name",
                                                          name: "customerName",
                                                          "data-test-id":
                                                            "invoice-plan-modal-customer-name",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs
                                                              .customerName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs,
                                                              "customerName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.customerName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "LabelWithTooltip",
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label: "Email address*",
                                                        tooltip:
                                                          "Customer details for the invoice.",
                                                      },
                                                    },
                                                    [
                                                      _c("MuiInputText", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate.blur",
                                                            value: {
                                                              isRequired: true,
                                                            },
                                                            expression:
                                                              "{\n                        isRequired: true,\n                      }",
                                                            modifiers: {
                                                              blur: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          type: "email",
                                                          placeholder:
                                                            "Enter email address",
                                                          name: "customerEmail",
                                                          "data-test-id":
                                                            "invoice-plan-modal-customer-email",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs
                                                              .customerEmail,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs,
                                                              "customerEmail",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.customerEmail",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "LabelWithTooltip",
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label: "Fiscal number*",
                                                        tooltip:
                                                          "Customer details for the invoice.",
                                                      },
                                                    },
                                                    [
                                                      _c("MuiInputText", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate.blur",
                                                            value: {
                                                              isRequired: true,
                                                            },
                                                            expression:
                                                              "{\n                        isRequired: true,\n                      }",
                                                            modifiers: {
                                                              blur: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          name: "customerCif",
                                                          placeholder:
                                                            "Enter fiscal number",
                                                          "data-test-id":
                                                            "invoice-plan-modal-fiscal-number",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inputs
                                                              .customerCif,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inputs,
                                                              "customerCif",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inputs.customerCif",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c("MuiInputText", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName:
                                                          "v-validate.blur",
                                                        value: {
                                                          isRequired: true,
                                                        },
                                                        expression:
                                                          "{\n                      isRequired: true,\n                    }",
                                                        modifiers: {
                                                          blur: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      label: "Address*",
                                                      name: "customerAddressLine1",
                                                      placeholder:
                                                        "Address line 1",
                                                      "data-test-id":
                                                        "invoice-plan-modal-address-line1",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inputs
                                                          .customerAddressLine1,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.inputs,
                                                          "customerAddressLine1",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "inputs.customerAddressLine1",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c("MuiInputText", {
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      name: "customerAddressLine2",
                                                      label:
                                                        "Floor, door, apartment...",
                                                      placeholder:
                                                        "Enter address",
                                                      "data-test-id":
                                                        "invoice-plan-modal-address-line2",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inputs
                                                          .customerAddressLine2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.inputs,
                                                          "customerAddressLine2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "inputs.customerAddressLine2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6 mt-4" },
                                                [
                                                  _c("MuiInputText", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName:
                                                          "v-validate.blur",
                                                        value: {
                                                          isRequired: true,
                                                        },
                                                        expression:
                                                          "{\n                      isRequired: true,\n                    }",
                                                        modifiers: {
                                                          blur: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      name: "customerAddressCity",
                                                      label: "City*",
                                                      placeholder: "City",
                                                      "data-test-id":
                                                        "invoice-plan-modal-city",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inputs
                                                          .customerAddressCity,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.inputs,
                                                          "customerAddressCity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "inputs.customerAddressCity",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6 mt-4" },
                                                [
                                                  _c("MuiInputText", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName:
                                                          "v-validate.blur",
                                                        value: {
                                                          isRequired: true,
                                                        },
                                                        expression:
                                                          "{\n                      isRequired: true,\n                    }",
                                                        modifiers: {
                                                          blur: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      name: "customerAddressPostalCode",
                                                      placeholder:
                                                        "Postal code",
                                                      label: "Postal code*",
                                                      "data-test-id":
                                                        "invoice-plan-modal-postal-code",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inputs
                                                          .customerAddressPostalCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.inputs,
                                                          "customerAddressPostalCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "inputs.customerAddressPostalCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-12 mt-4" },
                                                [
                                                  _c("MuiInputText", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName:
                                                          "v-validate.blur",
                                                        value: {
                                                          isRequired: true,
                                                          isPattern:
                                                            _vm.countryCodeValidator,
                                                        },
                                                        expression:
                                                          "{\n                      isRequired: true,\n                      isPattern: countryCodeValidator,\n                    }",
                                                        modifiers: {
                                                          blur: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      name: "customerAddressCountry",
                                                      label: "Country*",
                                                      placeholder:
                                                        "Enter 2 letter country code",
                                                      caption:
                                                        "Example: ES, UK, DE, IT, FR",
                                                      "data-test-id":
                                                        "invoice-plan-modal-country",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inputs
                                                          .customerAddressCountry,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.inputs,
                                                          "customerAddressCountry",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "inputs.customerAddressCountry",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "LabelWithTooltip",
                                                {
                                                  staticClass: "mt-4 col-12",
                                                  attrs: {
                                                    label:
                                                      "Other details (optional)",
                                                    tooltip:
                                                      "Customer details for the invoice.",
                                                  },
                                                },
                                                [
                                                  _c("MuiTextarea", {
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      name: "customerOtherDetails",
                                                      rows: "2",
                                                      placeholder:
                                                        "Contact person's name, email, phone, department",
                                                      "data-test-id":
                                                        "invoice-plan-modal-other-details",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inputs
                                                          .customerOtherDetails,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.inputs,
                                                          "customerOtherDetails",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "inputs.customerOtherDetails",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-content-center",
                                  },
                                  [
                                    _c("CancelButton", {
                                      attrs: {
                                        "data-test-id":
                                          "invoice-plan-modal-cancel",
                                      },
                                      on: { click: _vm.closeModal },
                                    }),
                                    _c(
                                      "SaveButtonDebounced",
                                      {
                                        attrs: {
                                          disabled: !areAllValid,
                                          type: _vm.BUTTON_TYPES.submit,
                                          narrow: "",
                                          "data-test-id":
                                            "invoice-plan-modal-send",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.invoicePlanUuid
                                                ? "Save"
                                                : "Create"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    "GenericModalComponent",
                    _vm.$attrs,
                    false
                  )
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }