var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        attrs: { "is-open": "" },
        on: {
          "modal-closed": function ($event) {
            return _vm.$emit("close-modal")
          },
        },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("ContentCellComponent", {
              staticClass: "col-md-6 mb-3",
              attrs: {
                value: _vm.vehicleData.licensePlate,
                label: "License plate",
              },
            }),
            _c("ContentCellComponent", {
              staticClass: "col-md-6 mb-3",
              attrs: { value: _vm.vehicleData.vehicleModel, label: "Model" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("ContentCellComponent", {
              staticClass: "col-md-6",
              attrs: {
                value: _vm.vehicleData.csOperator || _vm.FALLBACK_MESSAGE.dash,
                label: "CS operator",
              },
            }),
            _c(
              "ContentCellComponent",
              { staticClass: "col-md-6", attrs: { label: "Juridic status" } },
              [
                _c("ui-badge", {
                  attrs: {
                    color: _vm.find(
                      _vm.JURIDIC_STATUS_COLORS,
                      (key, value) => _vm.vehicleData.juridicStatus === value
                    ),
                    text: _vm.sentenceCase(_vm.vehicleData.juridicStatus),
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "row mt-3" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("ui-datetimepicker", {
                attrs: {
                  size: _vm.SIZES.small,
                  label: "Start date*",
                  skiptime: "",
                  "data-test-id": "sales_order-start_date",
                },
                domProps: { date: _vm.startDate },
                on: { datechanged: ({ detail }) => (_vm.startDate = detail) },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("ui-datetimepicker", {
                attrs: {
                  size: _vm.SIZES.small,
                  label: "End date*",
                  skiptime: "",
                  "data-test-id": "sales_order-end_date",
                },
                domProps: { date: _vm.endDate },
                on: { datechanged: ({ detail }) => (_vm.endDate = detail) },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c("SaveButton", {
            attrs: {
              loading: _vm.salesOrderStatus.LOADING,
              disabled: !_vm.startDate || !_vm.endDate,
              "data-test-id": "save-button",
            },
            on: { click: _vm.editVehicle },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }