var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b({ attrs: { "is-open": "" } }, "MuiModal", _vm.modalConfig, false),
    [
      _c(
        "MuiValidationWrapper",
        {
          attrs: { slot: "body", id: "salesOrderForm" },
          on: { areAllValid: (valid) => (_vm.isFormValid = valid) },
          slot: "body",
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("h3", { staticClass: "pb-4 px-1" }, [
                  _vm._v(" 1. Select type of your order "),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-3",
                  },
                  [_vm._v(" Order new or select existing vehicles ")]
                ),
                _c("ui-radio", {
                  staticClass: "mb-2 d-flex",
                  attrs: {
                    value: _vm.inputs.hasNewVehicles,
                    option: false,
                    name: "hasNewVehiclesOpt1",
                    caption: "Existing vehicles",
                  },
                  on: {
                    changevalue: () => (_vm.inputs.hasNewVehicles = false),
                  },
                }),
                _c("ui-radio", {
                  staticClass: "mb-2 d-flex",
                  attrs: {
                    value: _vm.inputs.hasNewVehicles,
                    option: true,
                    name: "hasNewVehiclesOpt2",
                    caption: "New vehicles",
                  },
                  on: { changevalue: () => (_vm.inputs.hasNewVehicles = true) },
                }),
                _c("div", { staticClass: "row mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("MuiAlgoliaSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          index: _vm.ALGOLIA_INDEXES.vehicleBrands,
                          title: (brand) => brand.name || "Unnamed brand",
                          label: "Vehicle brand",
                          "path-value": "uuid",
                          name: "brand",
                          placeholder: "Select",
                        },
                        model: {
                          value: _vm.inputs.brandUuid,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "brandUuid", $$v)
                          },
                          expression: "inputs.brandUuid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("MuiAlgoliaSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100 mb-2",
                        attrs: {
                          title: (model) => model.name || "Unnamed model",
                          filters: `brand.uuid:${_vm.inputs.brandUuid}`,
                          index: _vm.ALGOLIA_INDEXES.vehicleModels,
                          label: "Vehicle model",
                          "path-value": "uuid",
                          placeholder: "Select",
                          name: "model",
                        },
                        model: {
                          value: _vm.inputs.externalModelUuid,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "externalModelUuid", $$v)
                          },
                          expression: "inputs.externalModelUuid",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.inputs.hasNewVehicles
                  ? [
                      _vm.inputs.hasNewVehicles
                        ? _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100 mt-4",
                            attrs: {
                              type: _vm.INPUT_TYPES.number,
                              label: "Vehicle units",
                              name: "quantity",
                              placeholder: "Number of vehicles",
                              "data-test-id": "quantity",
                            },
                            model: {
                              value: _vm.inputs.quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "quantity", $$v)
                              },
                              expression: "inputs.quantity",
                            },
                          })
                        : _vm._e(),
                      _vm.inputs.hasNewVehicles
                        ? _c("MuiSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100 mt-4",
                            attrs: {
                              options: _vm.carColors,
                              label: "Vehicles color",
                              placeholder: "Select",
                              name: "color",
                              "data-test-id": "color",
                            },
                            model: {
                              value: _vm.inputs.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "color", $$v)
                              },
                              expression: "inputs.color",
                            },
                          })
                        : _vm._e(),
                    ]
                  : _c("MuiAlgoliaSelect", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                          },
                          expression:
                            "{\n            isRequired: true,\n          }",
                        },
                      ],
                      staticClass: "w-100 mt-4",
                      attrs: {
                        index: _vm.ALGOLIA_INDEXES.fpVehicles,
                        title: (result) => result.license_plate,
                        disabled: _vm.isVehiclesFieldDisabled,
                        filters: _vm.vehiclesFilter,
                        label: "Select from available vehicles",
                        "path-value": "uuid",
                        name: "vehicles",
                        placeholder: "Select",
                        "data-test-id": "vehicles",
                        multiple: "",
                      },
                      model: {
                        value: _vm.inputs.rawVehicles,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "rawVehicles", $$v)
                        },
                        expression: "inputs.rawVehicles",
                      },
                    }),
              ],
              2
            ),
            _c("div", { staticClass: "col-12" }, [
              _c("h3", { staticClass: "px-1 my-4" }, [
                _vm._v(" 2. Add contact details "),
              ]),
              _c("div", { staticClass: "row mt-4" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                          },
                          expression:
                            "{\n                isRequired: true,\n              }",
                        },
                      ],
                      staticClass: "w-100",
                      attrs: {
                        label: "Total months of sales order",
                        name: "monthsDuration",
                        type: _vm.INPUT_TYPES.number,
                        placeholder: "Introduce number of months",
                        "data-test-id": "monthsDuration",
                      },
                      model: {
                        value: _vm.inputs.monthsDuration,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "monthsDuration", _vm._n($$v))
                        },
                        expression: "inputs.monthsDuration",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                          },
                          expression:
                            "{\n                isRequired: true,\n              }",
                        },
                      ],
                      staticClass: "w-100",
                      attrs: {
                        type: _vm.INPUT_TYPES.number,
                        label: "Mileage limit per vehicle",
                        name: "contractedMileageValue",
                        placeholder: "Introduce total km",
                        "data-test-id": "mileage",
                      },
                      model: {
                        value: _vm.inputs.contractedMileageValue,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.inputs,
                            "contractedMileageValue",
                            _vm._n($$v)
                          )
                        },
                        expression: "inputs.contractedMileageValue",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row mt-4" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("MuiTextarea", {
                      staticClass: "w-100",
                      attrs: {
                        label: "Location",
                        name: "location",
                        placeholder:
                          "Where are these vehicles going to be located?",
                      },
                      model: {
                        value: _vm.inputs.location,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "location", $$v)
                        },
                        expression: "inputs.location",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("MuiTextarea", {
                      staticClass: "w-100",
                      attrs: {
                        label: "Remarks (optional)",
                        name: "remarks",
                        placeholder:
                          "Enter contact person’s name, email, phone, department...",
                      },
                      model: {
                        value: _vm.inputs.remarks,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "remarks", $$v)
                        },
                        expression: "inputs.remarks",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "div",
          {
            staticClass: "d-flex align-content-center justify-content-end p-3",
          },
          [
            _c("CancelButton", {
              on: {
                click: function ($event) {
                  return _vm.$emit("close-modal")
                },
              },
            }),
            _c(
              "SaveButtonDebounced",
              {
                staticClass: "mr-2",
                attrs: {
                  disabled: !_vm.isFormValid,
                  loading: _vm.salesOrderStatus.LOADING,
                },
                on: { click: _vm.createSalesOrder },
              },
              [_vm._v(" Create ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }