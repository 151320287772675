<script>
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';
import { contracts as contractsConfig } from './config/ContractsAlgoliaTables';
import ContractForm from './components/ContractFormComponent';
import { filterByCompany } from '../algoliaFilters/filterByCompany';

export default {
  name: 'ContractsView',
  components: {
    MuiAlgoliaList,
    ContractForm,
  },
  data() {
    return {
      isContractFormOpen: false,
      currentContractUuid: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.filterByCompany = filterByCompany;
    this.companyUuid = this.company.uuid;
    this.contractsConfig = contractsConfig(this.companyUuid);
    this.contractActions = [
      {
        label: 'Edit',
        method: this.openContractForm,
      },
    ];
  },
  methods: {
    getNoResultsLabel,
    openContractForm(contract) {
      this.currentContractUuid = contract
        ? contract.uuid
        : null;

      this.isContractFormOpen = true;
    },
    refreshTable() {
      refreshAlgoliaStore(this.$refs.contractTable, DELAY.long);
    },
    closeContractForm() {
      this.currentContract = null;
      this.isContractFormOpen = false;
    },
    onCreateContract() {
      this.openContractForm();
    },
  },
};
</script>

<template>
  <div class="ContractsView container-fluid">
    <h1 class="mb-2">
      Contracts list
    </h1>
    <div>
      <ui-card
        v-if="!isReloading"
        header="Dedicated fleet contracts"
        description="Select the vehicles, the service agreement and the service fees of your sales order."
      >
        <div slot="header-action">
          <ui-button @clickbutton="openContractForm">
            Create new contract
          </ui-button>
        </div>
        <MuiAlgoliaList
          ref="contractTable"
          :table-config="contractsConfig"
          :filters="filterByCompany(companyUuid)"
          :item-actions="contractActions"
          :index="ALGOLIA_INDEXES.fpContracts"
          :empty-message="getNoResultsLabel('contracts')"
        />
      </ui-card>
      <ContractForm
        v-if="isContractFormOpen"
        :key="currentContractUuid"
        :contract-uuid="currentContractUuid"
        :company-uuid="companyUuid"
        @closeModal="closeContractForm"
        @success="closeContractForm"
        @refresh-table="refreshTable"
      />
    </div>
  </div>
</template>
