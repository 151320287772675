var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        attrs: { "is-open": "" },
        on: {
          "modal-closed": function ($event) {
            return _vm.$emit("close-modal")
          },
        },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("div", [
            _vm._v(
              " Only vehicles that are not assigned to any sales order are displayed. "
            ),
          ]),
          _c("MuiAlgoliaSelect", {
            staticClass: "w-100 mt-4",
            attrs: {
              name: "sales-order-vehicles",
              index: _vm.ALGOLIA_INDEXES.fpVehicles,
              title: (result) => result.license_plate,
              filters: _vm.vehiclesFilter,
              label: "Select a vehicle*",
              placeholder: "Select a vehicle",
              "path-value": "uuid",
              "data-test-id": "vehicles-list",
            },
            model: {
              value: _vm.vehicleUuid,
              callback: function ($$v) {
                _vm.vehicleUuid = $$v
              },
              expression: "vehicleUuid",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                disabled: !_vm.vehicleUuid,
                loading: _vm.salesOrderStatus.LOADING,
                narrow: "",
                "data-test-id": "save-button",
              },
              on: { clickbutton: _vm.addVehicle },
            },
            [_vm._v(" Add ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }