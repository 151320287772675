<script>
import get from 'lodash/get';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
  TIME_ZONE,
} from '@emobg/web-utils';
import { BUTTON_TYPES, Validate } from '@emobg/vue-base';
import { FILE_VALIDATIONS } from '@/utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DragFileComponent,
  FileListComponent,
  GenericModalComponent,
} from '@/components';
import { CONTRACT_TYPES } from '../constants';

const parseContractDocument = contractDocument => {
  const document = { ...contractDocument };

  if (contractDocument.filename) {
    document.name = contractDocument.filename;
  }
  return document;
};

export default {
  name: 'ContractFormComponent',
  components: {
    DragFileComponent,
    CancelButton,
    GenericModalComponent,
    FileListComponent,
  },
  directives: {
    Validate,
  },
  props: {
    contractUuid: {
      type: String,
      required: false,
      default: undefined,
    },
    companyUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filesToUpload: [],
      isFormValid: { isValid: false },
      isValidFile: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleetInvoicing.contracts, {
      isLoading: state => state.STATUS.LOADING,
      isLoaded: state => state.STATUS.LOADED,
      isError: state => state.STATUS.ERROR,
      contractError: state => state.error,
      contract: state => state.data.contract,
      contractDocuments: state => state.data.contract.contractDocuments.map(parseContractDocument),
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone', TIME_ZONE.default),
    }),
    parsedContractDate() {
      return get(this, 'contract.startDate') ? moment(get(this, 'contract.startDate')) : null;
    },
    isEditMode() {
      return !!this.contract.uuid;
    },
  },
  watch: {
    isError(isError) {
      if (isError) {
        this.displayGenericError();
      }
    },
  },
  created() {
    this.initContract(this.contractUuid);
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.FILE_VALIDATIONS = FILE_VALIDATIONS;
    this.CONTRACT_TYPES = CONTRACT_TYPES;
    this.maxSizeInMB = 5;
    this.contract.companyUuid = this.companyUuid;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.contracts, [
      'saveContract',
      'initContract',
    ]),
    displayGenericError() {
      const message = get(
        this.contractError,
        'message',
        FALLBACK_MESSAGE.error,
      );

      this.$throwError({ message });
    },
    onCancel() {
      this.$emit('closeModal');
    },
    onSuccess() {
      this.$emit('refresh-table');
      this.$emit('success');
    },
    handleSuccess() {
      this.$notify({
        message: 'Contract created successfully',
        textAction: 'OK',
      });
      this.onSuccess();
    },
    async onSubmit() {
      if (this.isFormValid.isValid) {
        const {
          companyUuid, description, startDate, type, uuid,
        } = this.contract;

        const newContract = {
          contractUuid: this.contract.uuid,
          contract: {
            companyUuid,
            description,
            startDate: formatUtc(startDate, DATE_FORMAT.date, this.operatorTimezone),
            type,
            uuid,
          },
          binaryFiles: this.filesToUpload,
        };

        await this.saveContract(newContract);

        this.handleSuccess();
      }
    },
  },
};
</script>

<template>
  <ui-form
    validate
    @submitform="onSubmit"
  >
    <GenericModalComponent
      :header="{
        title: isEditMode ? 'Edit Contract' : 'Create Contract',
        isClosable: true,
      }"
      class="ContractFormComponent"
      v-on="$listeners"
    >
      <template #body>
        <ui-validate @status="({ detail }) => isFormValid = detail">
          <div class="mb-3">
            <ui-select
              v-validate.select="{
                isRequired: true,
              }"
              :value="contract.type"
              :options.prop="Object.values(CONTRACT_TYPES).map(contract => ({
                value: contract.code,
                label: contract.name
              }))"
              :disabled="isEditMode"
              label="Contract type*"
              placeholder="Select contract type"
              name="type"
              option-label="name"
              option-value="code"
              required
              @selectoption="({ detail }) => contract.type = detail"
            />
          </div>
          <div class="mb-3">
            <ui-datetimepicker
              :date.prop="parsedContractDate"
              :size="SIZES.small"
              label="Date when contract was created"
              skiptime
              name="start_date"
              data-test-id="contract_fort-datepicker"
              @datechanged="({ detail }) => contract.startDate = detail"
            />
          </div>
          <div class="mb-3">
            <ui-text-area
              :value="contract.description"
              placehholder="Write a description"
              class="d-block mb-4"
              name="description"
              label="Description"
              @changevalue="({ detail }) => contract.description = detail"
            />
          </div>
          <div class="emobg-body-small emobg-font-weight-semibold mb-1 px-1">
            Attachments
          </div>
          <div
            data-test-id="filesdrop-uploader"
            class="filesdrop-uploader"
          >
            <DragFileComponent
              v-model="filesToUpload"
              v-validate.files="{
                isMaxSize: FILE_VALIDATIONS.isMaxSize,
              }"
              :draggable-height="130"
              accepted-formats="image/*,application/pdf"
              multiple
              show-preview
              data-test-id="file-input"
            />
          </div>
          <FileListComponent
            :files="contractDocuments"
            data-test-id="contract-documents"
          />
        </ui-validate>
      </template>
      <template #footer>
        <div class="d-flex align-content-center">
          <CancelButton @click="onCancel" />
          <ui-button
            :disabled="!isFormValid.isValid"
            :loading="isLoading"
            narrow
            type="submit"
            data-test-id="save-button"
          >
            {{ isEditMode ? 'Save' : 'Create' }}
          </ui-button>
        </div>
      </template>
    </GenericModalComponent>
  </ui-form>
</template>
