<script>
export default {
  name: 'SummaryItemComponent',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<template>
  <div class="SummaryItemComponent col-md-6 col-lg-4 mb-3">
    <div class="emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1 px-1">
      {{ label }}
    </div>
    <p class="mt-2 mb-3 ml-1">
      {{ value }}
    </p>
  </div>
</template>
