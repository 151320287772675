import { RECORD_TYPES } from '@emobg/motion-ui';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';

import {
  DATE_FORMAT,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { PermissionLink } from '@/components';
import { SalesOrderTemplate } from '@/templates';
import {
  getNameFromList,
  normalizeDate,
} from '../../utils';

import { PERIODS } from '../../constants';

export const contracts = companyUuid => [
  {
    title: 'Contract',
    type: RECORD_TYPES.component,
    component: PermissionLink,
    displayPriority: 1,
    props: ({ uuid }) => ({
      class: 'emobg-font-weight-semibold',
      to: {
        name: crm.companies.detail.corporateContract.detail,
        params: {
          companyUuid,
          contractUuid: uuid,
        },
      },
      target: '_blank',
      linkPermissions: [CRM_PERMISSIONS.viewCrmCompanies],
      text: `${uuid.substr(0, 7)}`,
    }),
  },
  {
    attributeName: 'type',
    title: 'Contract type',
    displayPriority: 1,
    columnRatio: 1,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'start_date',
    title: 'Date of creation',
    displayPriority: 1,
    columnRatio: 1,
    transformValue: value => formatUtc(value, DATE_FORMAT.dob),
  },
  {
    attributeName: 'description',
    title: 'Description',
    displayPriority: 2,
    columnRatio: 2,
  },
];

export const salesOrders = (companyUuid, contractUuid) => [
  {
    title: 'Sales order #',
    type: RECORD_TYPES.component,
    component: PermissionLink,
    displayPriority: 1,
    props: ({ uuid }) => ({
      class: 'emobg-font-weight-semibold',
      to: {
        name: crm.companies.detail.corporateContract.salesOrders,
        params: {
          companyUuid,
          contractUuid,
          salesOrderUuid: uuid,
        },
      },
      target: '_blank',
      linkPermissions: [CRM_PERMISSIONS.viewCrmCompanies],
      text: `${uuid.substr(0, 7)}`,
    }),
  },
  {
    displayPriority: 1,
    title: 'Brand + Model',
    transformResult: result => `${sentenceCase(result.brand_name)} ${sentenceCase(result.model_name)}`,
  },
  {
    attributeName: 'quantity',
    displayPriority: 2,
    title: 'Quantity',
  },
  {
    attributeName: 'months_duration',
    displayPriority: 2,
    title: 'Duration in months',
  },
  {
    attributeName: 'contracted_mileage',
    displayPriority: 2,
    title: 'Mileage',
    transformResult: result => result.contracted_mileage.value,
  },
];

export function serviceFees() {
  return [
    {
      attributeName: 'service_type',
      displayPriority: 1,
      title: 'Service Type',
      minWidth: 200,
      transformValue: serviceType => this.getServiceType(serviceType),
    },
    {
      attributeName: 'name',
      displayPriority: 1,
      aspectRatio: 1.5,
      minWidth: 150,
      title: 'Name',
    },
    {
      attributeName: 'period_type',
      displayPriority: 2,
      title: 'Recurrence',
      minWidth: 80,
      transformValue: getNameFromList(PERIODS),
    },
    {
      attributeName: 'active_start_date',
      displayPriority: 2,
      title: 'Start date',
      minWidth: 90,
      transformValue: normalizeDate,
    },
    {
      attributeName: 'active_end_date',
      displayPriority: 2,
      title: 'End date',
      minWidth: 90,
      transformValue: normalizeDate,
    },
    {
      attributeName: 'amount_formatted',
      displayPriority: 2,
      title: 'Amount',
      minWidth: 80,
    },
    {
      attributeName: 'invoice_plan.name',
      displayPriority: 2,
      title: 'Invoice plan',
      minWidth: 80,
    },
    {
      attributeName: 'document',
      displayPriority: 2,
      title: 'Document',
      minWidth: 100,
    },
    {
      attributeName: 'description',
      displayPriority: 2,
      minWidth: 250,
      title: 'Description',
    },
    {
      attributeName: 'sales_order_uuid',
      displayPriority: 0,
      title: 'Sales order #',
      type: RECORD_TYPES.component,
      component: SalesOrderTemplate,
      minWidth: 100,
      props: result => ({
        salesOrderUuid: result.sales_order_uuid,
        companyUuid: this.companyUuid,
        contractUuid: this.contractUuid,
      }),
    },
    {
      attributeName: 'related_invoice_reference',
      displayPriority: 0,
      title: 'Related invoice',
      minWidth: 200,
    },
  ];
}
