import get from 'lodash/get';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { FALLBACK_MESSAGE, TIME_ZONE, sentenceCase } from '@emobg/web-utils';

import { normalizeDate } from '../../utils';

export const vehicles = () => [
  {
    displayPriority: 1,
    title: 'Vehicle ID',
    attributeName: 'vehicle_uuid',
    type: RECORD_TYPES.template,
    template: ({ vehicle_uuid: vehicleUuid }) => (`
      <a
        class="emobg-link-primary emobg-body-2"
        href = "/fleet/vehicles/${vehicleUuid}/details/setup"
      >
        ${vehicleUuid.substr(0, 7)}
      </a>
    `),
  },
  {
    attributeName: 'license_plate',
    displayPriority: 1,
    title: 'License plate',
    transformValue: plate => plate || FALLBACK_MESSAGE.notAvailable,
  },
  {
    attributeName: 'color',
    displayPriority: 1,
    title: 'Color',
    transformValue: sentenceCase,
  },
  {
    attributeName: 'period_start',
    displayPriority: 2,
    title: 'Start date',
    transformValue: normalizeDate,
  },
  {
    attributeName: 'period_end',
    displayPriority: 2,
    title: 'End date',
    transformValue: normalizeDate,
  },
  {
    displayPriority: 2,
    title: 'Mileage contracted',
    transformResult: ({ mileage }) => `${mileage.current}/${mileage.limit}${mileage.unit}`,
  },
  {
    attributeName: 'status',
    displayPriority: 2,
    title: 'Status',
    transformValue: sentenceCase,
  },
];

export const salesOrderVehiclesFacets = () => [
  {
    type: 'refinementList',
    props: {
      title: 'Color',
      attributeName: 'color',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Vehicle status',
      transformValue: sentenceCase,
      attributeName: 'vehicle_status',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      transformValue: sentenceCase,
      attributeName: 'status',
    },
  },
  {
    type: 'rangeDateTimePicker',
    props: {
      title: 'Start date',
      attributeName: 'period_start',
      timezone: get(this, 'operator.timezone', TIME_ZONE.default),
      labels: {
        from: 'Start date',
        to: 'End date',
        removeButton: 'Clear dates',
      },
    },
  },
  {
    type: 'rangeDateTimePicker',
    props: {
      title: 'End date',
      attributeName: 'period_end',
      timezone: get(this, 'operator.timezone', TIME_ZONE.default),
      labels: {
        from: 'Start date',
        to: 'End date',
        removeButton: 'Clear dates',
      },
    },
  },
];
