var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SalesOrdersDetailView" },
    [
      _c("h1", { staticClass: "mb-3" }, [_vm._v(" Sales order detail ")]),
      _c(
        "ui-card",
        {
          staticClass: "mb-3",
          attrs: {
            header: "Vehicle list",
            description: "Vehicles associated to this sales order.",
          },
        },
        [
          _vm.salesOrderSummaryInfo
            ? _c(
                "section",
                { staticClass: "SalesOrdersDetailView__Summary row" },
                _vm._l(_vm.salesOrderSummaryInfo, function (item) {
                  return _c("SummaryItem", {
                    key: item.label,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              )
            : _vm._e(),
          _c(
            "section",
            { staticClass: "SalesOrdersDetailView__List" },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-end mb-2" },
                [
                  _c(
                    "ui-button",
                    {
                      on: {
                        clickbutton: function ($event) {
                          _vm.isAddVehicleModalOpen = true
                        },
                      },
                    },
                    [_vm._v(" Add vehicle ")]
                  ),
                ],
                1
              ),
              _c("MuiAlgoliaList", {
                ref: "vehiclesTable",
                staticClass: "p-3",
                attrs: {
                  "table-config": _vm.contentCells,
                  facets: _vm.facets,
                  filters: `sales_order_uuid:${_vm.salesOrderUuid}`,
                  index: _vm.ALGOLIA_INDEXES.fpSalesOrderVehicles,
                  "item-actions": _vm.algoliaItemActions,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.isAddVehicleModalOpen
        ? _c("SalesOrderAddVehicleModal", {
            attrs: {
              "vehicle-model-uuid": _vm.externalModelUuid,
              "sales-order-uuid": _vm.salesOrderUuid,
            },
            on: { "close-modal": _vm.closeModals },
          })
        : _vm._e(),
      _vm.isEditVehicleModalOpen
        ? _c("SalesOrderEditVehicleModal", {
            attrs: {
              "sales-order-uuid": _vm.salesOrderUuid,
              "vehicle-data": _vm.currentVehicle,
            },
            on: { "close-modal": _vm.closeModals },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }