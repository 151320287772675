import get from 'lodash/get';

export const summaryItemInfo = salesOrder => (
  salesOrder && ([
    {
      label: 'Sales order ID',
      value: get(salesOrder, 'uuid').substr(0, 7),
    },
    {
      label: 'Brand + Model',
      value: `${get(salesOrder, 'brandName')} ${get(salesOrder, 'modelName')}`,
    },
    {
      label: 'Quantity',
      value: get(salesOrder, 'quantity'),
    },
    {
      label: 'Mileage limit per vehicle',
      value: get(salesOrder, 'contractedMileage.value'),
    },
    {
      label: 'Total months',
      value: `${get(salesOrder, 'monthsDuration')}`,
    },
    {
      label: 'Location',
      value: get(salesOrder, 'location'),
    },
    {
      label: 'Remarks',
      value: get(salesOrder, 'remarks'),
    },
  ])
);
