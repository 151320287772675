import compact from 'lodash/compact';

export default modelUuid => {
  const computedFilters = compact([
    // remove falsey values
    modelUuid && `external_model_uuid:${modelUuid}`,
  ]);

  return computedFilters.length > 0
    ? computedFilters.join(' AND ').concat(' AND is_available:true')
    : '';
};
