<script>
import get from 'lodash/get';
import some from 'lodash/some';
import { MuiSelect } from '@emobg/motion-ui/v1';

export default {
  components: {
    MuiSelect,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    invoiceDocuments: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      invoiceDocument: '',
    };
  },
  mounted() {
    this.invoiceDocument = this.value;
  },
  methods: {
    get,
    addInvoiceDocument() {
      const searchText = get(this, '$refs.documentSelector.searchboxText', '');

      const newDocument = {
        value: searchText,
        label: searchText,
      };

      const isDocumentInDocuments = some(this.invoiceDocuments, newDocument);

      if (!isDocumentInDocuments) {
        this.invoiceDocuments.push(newDocument);
      }

      this.$nextTick(() => {
        this.invoiceDocument = searchText;
        this.$refs.documentSelector.close();
        this.$emit('change', this.invoiceDocument);
      });
    },
  },
};
</script>
<template>
  <MuiSelect
    ref="documentSelector"
    v-model="invoiceDocument"
    :options="invoiceDocuments"
    :size="SIZES.medium"
    :searchbox="{ threshold: -1 }"
    :label="label"
    :normalize="false"
    name="invoiceDocument"
    placeholder="Select or add document"
    class="w-100"
    data-test-id="document-selector"
    @change="$emit('change', invoiceDocument)"
  >
    <template slot="list-actions">
      <div
        v-if="get($refs, 'documentSelector.searchboxText', '').length"
        class="d-flex justify-content-between align-items-center emobg-background-color-white px-2"
      >
        <p>{{ get($refs, 'documentSelector.searchboxText', '') }}</p>
        <ui-button
          :face="FACES.text"
          :size="SIZES.medium"
          :color="COLORS.primary"
          square
          data-test-id="document-selector-add"
          @clickbutton="addInvoiceDocument"
        >
          Add
        </ui-button>
      </div>
    </template>
  </MuiSelect>
</template>
