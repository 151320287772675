<script>
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiModal,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, SaveButtonDebounced } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import algoliaVehiclesFilter from './algoliaVehiclesFilter';
import { carColors } from './carColors';

export default {
  name: 'SalesOrderFormComponent',
  components: {
    CancelButton,
    MuiInputText,
    MuiTextarea,
    SaveButtonDebounced,
    MuiSelect,
    MuiModal,
    MuiAlgoliaSelect,
    MuiValidationWrapper,
  },
  directives: {
    Validate,
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        hasNewVehicles: false,
        rawVehicles: [],
        brandUuid: null,
        color: null,
        contractedMileageValue: null,
        externalModelUuid: null,
        monthsDuration: null,
        quantity: null,
        location: null,
        remarks: null,
      },
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.fleetInvoicing.salesOrders, {
      salesOrderStatus: state => get(state, 'STATUS'),
      errorOrderError: state => state.error,
    }),
    vehiclesFilter() {
      return this.algoliaVehiclesFilter(this.inputs.externalModelUuid);
    },
    isVehiclesFieldDisabled() {
      return !(this.inputs.externalModelUuid && this.inputs.brandUuid);
    },
  },
  created() {
    this.contractUuid = get(this.$route, 'params.contractUuid');
    this.algoliaVehiclesFilter = algoliaVehiclesFilter;
    this.carColors = carColors;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.modalConfig = {
      title: 'Create sales order vehicles',
      header: {
        isClosable: true,
        class: 'pl-3',
      },
    };
  },
  mounted() {
    this.$watch(vm => [
      vm.inputs.brandUuid,
      vm.inputs.externalModelUuid,
      vm.inputs.hasNewVehicles,
    ], this.resetVehiclesDetails);
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.app.messages.errors, [
      'throwError',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.salesOrders, [
      'postSalesOrderNewVehicles',
      'postSalesOrderExistingVehicles',
    ]),
    async createSalesOrder() {
      if (this.isFormValid) {
        const defaultRequest = {
          location: this.inputs.location,
          contractedMileageValue: this.inputs.contractedMileageValue,
          remarks: this.inputs.remarks,
          monthsDuration: this.inputs.monthsDuration,
          contractUuid: this.contractUuid,
        };
        if (this.inputs.hasNewVehicles) {
          await this.postSalesOrderNewVehicles({
            ...defaultRequest,
            externalModelUuid: this.inputs.externalModelUuid,
            color: this.inputs.color,
            quantity: toNumber(this.inputs.quantity),
          });
        } else {
          await this.postSalesOrderExistingVehicles({
            ...defaultRequest,
            vehicles: this.inputs.rawVehicles,
          });
        }
        if (this.salesOrderStatus.ERROR) {
          const message = get(this.errorOrderError, 'message', FALLBACK_MESSAGE.error);
          this.throwError({ message });
        } else {
          this.$emit('refresh-table');
          this.notify({ message: 'Sales order successfully saved' });
          this.$emit('close-modal');
        }
      }
    },
    resetVehiclesDetails() {
      this.inputs.rawVehicles = [];
      this.inputs.quantity = null;
      this.inputs.color = null;
    },
  },
};
</script>

<template>
  <MuiModal
    v-bind="modalConfig"
    is-open
  >
    <MuiValidationWrapper
      id="salesOrderForm"
      slot="body"
      @areAllValid="valid => isFormValid = valid"
    >
      <div class="row">
        <div class="col-12">
          <h3 class="pb-4 px-1">
            1. Select type of your order
          </h3>
          <div class="emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-3">
            Order new or select existing vehicles
          </div>
          <ui-radio
            :value="inputs.hasNewVehicles"
            :option="false"
            name="hasNewVehiclesOpt1"
            class="mb-2 d-flex"
            caption="Existing vehicles"
            @changevalue="() => inputs.hasNewVehicles = false"
          />
          <ui-radio
            :value="inputs.hasNewVehicles"
            :option="true"
            name="hasNewVehiclesOpt2"
            caption="New vehicles"
            class="mb-2 d-flex"
            @changevalue="() => inputs.hasNewVehicles = true"
          />
          <div class="row mt-4">
            <div class="col-6">
              <MuiAlgoliaSelect
                v-model="inputs.brandUuid"
                v-validate="{
                  isRequired: true,
                }"
                :index="ALGOLIA_INDEXES.vehicleBrands"
                :title="brand => brand.name || 'Unnamed brand'"
                label="Vehicle brand"
                path-value="uuid"
                class="w-100"
                name="brand"
                placeholder="Select"
              />
            </div>
            <div class="col-6">
              <MuiAlgoliaSelect
                v-model="inputs.externalModelUuid"
                v-validate="{
                  isRequired: true,
                }"
                :title="model => model.name || 'Unnamed model'"
                :filters="`brand.uuid:${inputs.brandUuid}`"
                :index="ALGOLIA_INDEXES.vehicleModels"
                label="Vehicle model"
                path-value="uuid"
                class="w-100 mb-2"
                placeholder="Select"
                name="model"
              />
            </div>
          </div>
          <template v-if="inputs.hasNewVehicles">
            <MuiInputText
              v-if="inputs.hasNewVehicles"
              v-model="inputs.quantity"
              v-validate="{
                isRequired: true,
              }"
              :type="INPUT_TYPES.number"
              class="w-100 mt-4"
              label="Vehicle units"
              name="quantity"
              placeholder="Number of vehicles"
              data-test-id="quantity"
            />
            <MuiSelect
              v-if="inputs.hasNewVehicles"
              v-model="inputs.color"
              v-validate="{
                isRequired: true,
              }"
              :options="carColors"
              label="Vehicles color"
              placeholder="Select"
              name="color"
              data-test-id="color"
              class="w-100 mt-4"
            />
          </template>
          <MuiAlgoliaSelect
            v-else
            v-model="inputs.rawVehicles"
            v-validate="{
              isRequired: true,
            }"
            :index="ALGOLIA_INDEXES.fpVehicles"
            :title="result => result.license_plate"
            :disabled="isVehiclesFieldDisabled"
            :filters="vehiclesFilter"
            label="Select from available vehicles"
            path-value="uuid"
            name="vehicles"
            placeholder="Select"
            data-test-id="vehicles"
            class="w-100 mt-4"
            multiple
          />
        </div>
        <div class="col-12">
          <h3 class="px-1 my-4">
            2. Add contact details
          </h3>
          <div class="row mt-4">
            <div class="col-6">
              <MuiInputText
                v-model.number="inputs.monthsDuration"
                v-validate="{
                  isRequired: true,
                }"
                label="Total months of sales order"
                class="w-100"
                name="monthsDuration"
                :type="INPUT_TYPES.number"
                placeholder="Introduce number of months"
                data-test-id="monthsDuration"
              />
            </div>
            <div class="col-6">
              <MuiInputText
                v-model.number="inputs.contractedMileageValue"
                v-validate="{
                  isRequired: true,
                }"
                :type="INPUT_TYPES.number"
                label="Mileage limit per vehicle"
                class="w-100"
                name="contractedMileageValue"
                placeholder="Introduce total km"
                data-test-id="mileage"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <MuiTextarea
                v-model="inputs.location"
                class="w-100"
                label="Location"
                name="location"
                placeholder="Where are these vehicles going to be located?"
              />
            </div>
            <div class="col-6">
              <MuiTextarea
                v-model="inputs.remarks"
                label="Remarks (optional)"
                class="w-100"
                name="remarks"
                placeholder="Enter contact person’s name, email, phone, department..."
              />
            </div>
          </div>
        </div>
      </div>
    </MuiValidationWrapper>
    <div slot="footer">
      <div class="d-flex align-content-center justify-content-end p-3">
        <CancelButton @click="$emit('close-modal');" />

        <SaveButtonDebounced
          :disabled="!isFormValid"
          :loading="salesOrderStatus.LOADING"
          class="mr-2"
          @click="createSalesOrder"
        >
          Create
        </SaveButtonDebounced>
      </div>
    </div>
  </MuiModal>
</template>
