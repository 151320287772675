<script>
export default {
  name: 'CompanyContractsView',
};
</script>

<template>
  <div
    class="CompanyContractsView"
    data-test-id="company_contracts-view"
  >
    <RouterView />
  </div>
</template>
