var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SummaryItemComponent col-md-6 col-lg-4 mb-3" },
    [
      _c(
        "div",
        {
          staticClass:
            "emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1 px-1",
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _c("p", { staticClass: "mt-2 mb-3 ml-1" }, [
        _vm._v(" " + _vm._s(_vm.value) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }