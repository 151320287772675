<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY } from '@emobg/web-utils';
import { refreshAlgoliaStore } from '@/utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import SummaryItem from './components/SummaryItemComponent';
import SalesOrderAddVehicleModal from './components/SalesOrderAddVehicleModal/SalesOrderAddVehicleModal';
import SalesOrderEditVehicleModal from './components/SalesOrderEditVehicleModal/SalesOrderEditVehicleModal';

import {
  salesOrderVehiclesFacets,
  vehicles as vehiclesListConfig,
} from './config/SalesOrdersAlgoliaTables';
import { summaryItemInfo } from './const/salesOrders.const';

export default {
  name: 'SalesOrdersDetailView',
  components: {
    SummaryItem,
    MuiAlgoliaList,
    SalesOrderAddVehicleModal,
    SalesOrderEditVehicleModal,
  },
  data() {
    return {
      isAddVehicleModalOpen: false,
      isEditVehicleModalOpen: false,
      currentVehicle: undefined,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleetInvoicing.salesOrders, {
      salesOrderError: state => state.error,
      salesOrder: state => get(state, 'data.data'),
      salesOrderSummaryInfo: state => summaryItemInfo(get(state, 'data.data')),
    }),
    externalModelUuid() {
      return get(this, 'salesOrder.externalModelUuid');
    },
    salesOrderModel() {
      return `${get(this, 'salesOrder.brandName')} ${get(this, 'salesOrder.modelName')}`;
    },
  },
  watch: {
    salesOrderError(statusError) {
      // TODO: BL-128 Handle this error with scope to avoid overlapping
      // with other sales orders errors
      if (statusError) {
        this.$notify({
          message: 'Unable to retrieve sales order summary information.',
          textAction: 'OK',
          type: NOTIFICATION_TYPES.error,
        });
      }
    },
  },
  async created() {
    const { salesOrderUuid } = this.$route.params;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.salesOrderUuid = salesOrderUuid;
    this.contentCells = vehiclesListConfig();
    this.facets = salesOrderVehiclesFacets();
    this.algoliaItemActions = [
      {
        label: 'Edit vehicle',
        method: result => {
          this.currentVehicle = {
            uuid: result.uuid,
            licensePlate: result.license_plate,
            vehicleModel: this.salesOrderModel,
            csOperator: result.current_operator,
            juridicStatus: result.vehicle_status,
            periodStart: result.period_start,
            periodEnd: result.period_end,
          };
          this.isEditVehicleModalOpen = true;
        },
      },
    ];
    await this.getSalesOrder(salesOrderUuid);
  },
  methods: {
    refreshAlgoliaStore,
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.salesOrders, [
      'getSalesOrder',
    ]),
    closeModals() {
      this.isAddVehicleModalOpen = false;
      this.isEditVehicleModalOpen = false;
      this.refreshAlgoliaStore(this.$refs.vehiclesTable, DELAY.long);
    },
  },
};
</script>

<template>
  <div class="SalesOrdersDetailView">
    <h1 class="mb-3">
      Sales order detail
    </h1>
    <ui-card
      header="Vehicle list"
      description="Vehicles associated to this sales order."
      class="mb-3"
    >
      <section
        v-if="salesOrderSummaryInfo"
        class="SalesOrdersDetailView__Summary row"
      >
        <SummaryItem
          v-for="item in salesOrderSummaryInfo"
          :key="item.label"
          :value="item.value"
          :label="item.label"
        />
      </section>
      <section class="SalesOrdersDetailView__List">
        <div class="d-flex justify-content-end mb-2">
          <ui-button @clickbutton="isAddVehicleModalOpen = true">
            Add vehicle
          </ui-button>
        </div>
        <MuiAlgoliaList
          ref="vehiclesTable"
          :table-config="contentCells"
          :facets="facets"
          :filters="`sales_order_uuid:${salesOrderUuid}`"
          :index="ALGOLIA_INDEXES.fpSalesOrderVehicles"
          :item-actions="algoliaItemActions"
          class="p-3"
        />
      </section>
    </ui-card>
    <SalesOrderAddVehicleModal
      v-if="isAddVehicleModalOpen"
      :vehicle-model-uuid="externalModelUuid"
      :sales-order-uuid="salesOrderUuid"
      @close-modal="closeModals"
    />
    <SalesOrderEditVehicleModal
      v-if="isEditVehicleModalOpen"
      :sales-order-uuid="salesOrderUuid"
      :vehicle-data="currentVehicle"
      @close-modal="closeModals"
    />
  </div>
</template>
