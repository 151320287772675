import { VALIDATION_PATTERN } from '@emobg/web-utils';

export const isNumeric = {
  pattern: VALIDATION_PATTERN.decimalNumber,
  message: 'The value should be a number',
};

export const isAmount = {
  pattern: /^\d+[.|,]\d{2}$/,
  message: 'The value should be a valid amount: 100.00',
};
