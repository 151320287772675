export const CHARGES = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'All Vehicles',
    value: 'all_vehicles',
  },
  {
    label: 'Sales order',
    value: 'sales_order',
  },
];

export const PERIODS = {
  once: 'once',
  weekly: 'weekly',
  monthly: 'monthly',
};

export const SERVICE_TYPES = {
  refund: 'refund',
  managementFeeForPrivateUse: 'management_fee_for_private_use',
  nonRevenueRefundForPrivateUse: 'non_revenue_refund_for_private_use',
};
