var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-form",
    { attrs: { validate: "" }, on: { submitform: _vm.onSubmit } },
    [
      _c(
        "GenericModalComponent",
        _vm._g(
          {
            staticClass: "ContractFormComponent",
            attrs: {
              header: {
                title: _vm.isEditMode ? "Edit Contract" : "Create Contract",
                isClosable: true,
              },
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "ui-validate",
                      {
                        on: {
                          status: ({ detail }) => (_vm.isFormValid = detail),
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mb-3" },
                          [
                            _c("ui-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.select",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n              isRequired: true,\n            }",
                                  modifiers: { select: true },
                                },
                              ],
                              attrs: {
                                value: _vm.contract.type,
                                disabled: _vm.isEditMode,
                                label: "Contract type*",
                                placeholder: "Select contract type",
                                name: "type",
                                "option-label": "name",
                                "option-value": "code",
                                required: "",
                              },
                              domProps: {
                                options: Object.values(_vm.CONTRACT_TYPES).map(
                                  (contract) => ({
                                    value: contract.code,
                                    label: contract.name,
                                  })
                                ),
                              },
                              on: {
                                selectoption: ({ detail }) =>
                                  (_vm.contract.type = detail),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-3" },
                          [
                            _c("ui-datetimepicker", {
                              attrs: {
                                size: _vm.SIZES.small,
                                label: "Date when contract was created",
                                skiptime: "",
                                name: "start_date",
                                "data-test-id": "contract_fort-datepicker",
                              },
                              domProps: { date: _vm.parsedContractDate },
                              on: {
                                datechanged: ({ detail }) =>
                                  (_vm.contract.startDate = detail),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-3" },
                          [
                            _c("ui-text-area", {
                              staticClass: "d-block mb-4",
                              attrs: {
                                value: _vm.contract.description,
                                placehholder: "Write a description",
                                name: "description",
                                label: "Description",
                              },
                              on: {
                                changevalue: ({ detail }) =>
                                  (_vm.contract.description = detail),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "emobg-body-small emobg-font-weight-semibold mb-1 px-1",
                          },
                          [_vm._v(" Attachments ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "filesdrop-uploader",
                            attrs: { "data-test-id": "filesdrop-uploader" },
                          },
                          [
                            _c("DragFileComponent", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.files",
                                  value: {
                                    isMaxSize: _vm.FILE_VALIDATIONS.isMaxSize,
                                  },
                                  expression:
                                    "{\n              isMaxSize: FILE_VALIDATIONS.isMaxSize,\n            }",
                                  modifiers: { files: true },
                                },
                              ],
                              attrs: {
                                "draggable-height": 130,
                                "accepted-formats": "image/*,application/pdf",
                                multiple: "",
                                "show-preview": "",
                                "data-test-id": "file-input",
                              },
                              model: {
                                value: _vm.filesToUpload,
                                callback: function ($$v) {
                                  _vm.filesToUpload = $$v
                                },
                                expression: "filesToUpload",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("FileListComponent", {
                          attrs: {
                            files: _vm.contractDocuments,
                            "data-test-id": "contract-documents",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex align-content-center" },
                      [
                        _c("CancelButton", { on: { click: _vm.onCancel } }),
                        _c(
                          "ui-button",
                          {
                            attrs: {
                              disabled: !_vm.isFormValid.isValid,
                              loading: _vm.isLoading,
                              narrow: "",
                              type: "submit",
                              "data-test-id": "save-button",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.isEditMode ? "Save" : "Create") +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }