export const CONTRACT_TYPES = {
  CARSHARING: {
    name: 'Corporate carsharing',
    code: 'corporate_carsharing',
  },
  FLEET: {
    name: 'Fleet management',
    code: 'fleet_management',
  },
  SERVICE: {
    name: 'Service only',
    code: 'service_only',
  },
};

export const PERIODS = [
  {
    name: 'Monthly',
    code: 'monthly',
  },
  {
    name: 'Quarterly',
    code: 'quarterly',
  },
  {
    name: 'Yearly',
    code: 'yearly',
  },
];

export const NETD_LIST = ['15', '30', '60', '90'];
