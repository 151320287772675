export const carColors = [
  'Black',
  'Blue',
  'Brown',
  'Green',
  'Gray',
  'Orange',
  'Pink',
  'Purple',
  'Red',
  'Silver',
  'White',
  'Beige',
  'Golden',
  'Bronze',
];
