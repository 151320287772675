import { render, staticRenderFns } from "./DocumentSelectorComponent.vue?vue&type=template&id=107a2e21&"
import script from "./DocumentSelectorComponent.vue?vue&type=script&lang=js&"
export * from "./DocumentSelectorComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('107a2e21')) {
      api.createRecord('107a2e21', component.options)
    } else {
      api.reload('107a2e21', component.options)
    }
    module.hot.accept("./DocumentSelectorComponent.vue?vue&type=template&id=107a2e21&", function () {
      api.rerender('107a2e21', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/FleetInvoicing/ServiceFees/components/DocumentSelectorComponent.vue"
export default component.exports