import { DATE_FORMAT, FALLBACK_MESSAGE, formatUtc } from '@emobg/web-utils';
import { formatUuid } from '../../utils';

export const invoicePlanAlgoliaTables = operatorTimezone => {
  const formatDate = value => (value ? formatUtc(value, DATE_FORMAT.dob, operatorTimezone) : FALLBACK_MESSAGE.dash);

  return [
    {
      attributeName: 'uuid',
      displayPriority: 1,
      title: 'ID',
      transformValue: formatUuid,
    },
    {
      attributeName: 'billing_reference',
      displayPriority: 1,
      title: 'Billing reference',
    },
    {
      attributeName: 'first_invoice_date',
      displayPriority: 1,
      title: 'First invoice date',
      columnRatio: 1,
      transformValue: formatDate,
    },
    {
      attributeName: 'period',
      displayPriority: 1,
      title: 'Period',
      columnRatio: 1,
    },
    {
      attributeName: 'net_days',
      displayPriority: 1,
      title: 'NetD',
    },
    { attributeName: 'customer.name', displayPriority: 1, title: 'Customer' },
    {
      attributeName: 'purchase_order_number',
      title: 'Purchase order number',
      displayPriority: 1,
    },
    {
      attributeName: 'created_at',
      displayPriority: 1,
      title: 'Creation date',
      columnRatio: 1,
      transformValue: formatDate,
    },
    {
      attributeName: 'updated_at',
      displayPriority: 1,
      title: 'Edition date',
      columnRatio: 1,
      transformValue: formatDate,
    },
  ];
};
