<script>
import get from 'lodash/get';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY, FALLBACK_MESSAGE, TIME_ZONE } from '@emobg/web-utils';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';
import {
  salesOrders as salesOrderListConfig,
  serviceFees as serviceFeesListConfig,
} from './config/ContractsAlgoliaTables';
import { invoicePlanAlgoliaTables } from '../InvoicePlan/config/InvoicePlansAlgoliaTables';
import SalesOrderForm from '../SalesOrders/components/SalesOrderFormComponent';
import ServiceFeesModal from '../ServiceFees/ServiceFeesModal';
import InvoicePlanForm from '../InvoicePlan/components/InvoicePlanForm';
import { filterByContract } from '../algoliaFilters/filterByContract';

export default {
  name: 'ContractDetailView',
  components: {
    MuiAlgoliaList,
    InvoicePlanForm,
    ServiceFeesModal,
    SalesOrderForm,
  },
  data() {
    return {
      invoicePlanUuid: null,
      isInvoicePlanOpen: false,
      isServiceFeesModalOpen: false,
      isSalesOrderModalOpen: false,
      serviceFee: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    ...mapState(DOMAINS_MODEL.fleetInvoicing.invoicePlan, {
      invoicePlanFormOpen: state => get(state, 'invoicePlanFormOpen'),
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone', TIME_ZONE.default),
    }),
    ...mapState(DOMAINS_MODEL.fleetInvoicing.serviceTypes, {
      serviceTypes: state => get(state, 'serviceTypes.data.data', []),
    }),
  },
  created() {
    // Set up constants
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.companyUuid = this.company.uuid;
    this.contractUuid = get(this.$route, 'params.contractUuid');
    this.getServiceTypes(this.companyUuid);
    this.salesOrderListConfig = salesOrderListConfig(this.companyUuid, this.contractUuid);
    this.invoicePlanAlgoliaConfig = invoicePlanAlgoliaTables(this.operatorTimezone);
    this.serviceFeesListConfig = serviceFeesListConfig.bind(this)();

    this.filterByContract = filterByContract;

    // Actions that can be triggered per table row
    this.serviceFeesActions = [
      {
        label: 'Edit',
        method: serviceFee => this.openServiceFeesForm(serviceFee),
      },
    ];
    this.invoicePlansActions = [
      {
        label: 'Edit',
        method: invoicePlan => this.openInvoicePlanForm(invoicePlan.uuid),
      },
    ];

    this.invoicePlanTableActions = [
      {
        label: 'Create plan',
        class: 'emobg-color-primary',
        method: this.createInvoicePlan,
      },
    ];

    this.salesOrderTableActions = [
      {
        label: 'New sales order',
        class: 'emobg-color-primary',
        method: this.openSalesOrderForm,
      },
    ];

    this.serviceFeesTableActions = [
      {
        label: 'Add service fee',
        class: 'emobg-color-primary',
        method: () => this.openServiceFeesForm(null),
      },
    ];

    const autoOpenInvoicePlan = get(this, '$route.query.openInvoicePlanUuid');
    if (autoOpenInvoicePlan) {
      this.openInvoicePlanForm(autoOpenInvoicePlan);
    }
  },
  methods: {
    getNoResultsLabel,
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.serviceTypes, [
      'getServiceTypes',
    ]),
    getServiceType(serviceType) {
      return get(this.serviceTypes, serviceType, FALLBACK_MESSAGE.dash);
    },
    createInvoicePlan() {
      this.openInvoicePlanForm(null);
    },
    openInvoicePlanForm(invoicePlanUuid) {
      this.isInvoicePlanOpen = true;
      this.invoicePlanUuid = invoicePlanUuid;
    },
    onCloseInvoicePlanModalForm() {
      this.isInvoicePlanOpen = false;
      this.invoicePlanUuid = null;
      this.closeInvoicePlanModalForm();
    },
    onCloseServiceFeesModal() {
      this.isServiceFeesModalOpen = false;
      this.serviceFee = null;
    },
    onCloseSalesOrderForm() {
      this.isSalesOrderModalOpen = false;
    },
    openSalesOrderForm() {
      this.isSalesOrderModalOpen = true;
    },
    openServiceFeesForm(serviceFee) {
      this.serviceFee = serviceFee;
      this.isServiceFeesModalOpen = true;
    },
    onRefreshTable(tableRef) {
      refreshAlgoliaStore(this.$refs[tableRef], DELAY.long);
    },
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.invoicePlan, [
      'getInvoicePlan',
    ]),
    ...mapMutations(DOMAINS_MODEL.fleetInvoicing.invoicePlan, [
      'clearInvoicePlan',
      'closeInvoicePlanModalForm',
    ]),
  },
};
</script>

<template>
  <div class="ContractDetailView">
    <h1 class="mb-3">
      Contract details
    </h1>
    <ui-card
      header="Invoice Plans"
      class="mb-3 d-block"
    >
      <MuiAlgoliaList
        ref="invoicePlanTable"
        :actions="invoicePlanTableActions"
        :table-config="invoicePlanAlgoliaConfig"
        :filters="filterByContract(contractUuid)"
        :item-actions="invoicePlansActions"
        :index="ALGOLIA_INDEXES.fpInvoicePlans"
        :empty-message="getNoResultsLabel('invoice plans')"
        class="p-3"
      />
    </ui-card>
    <InvoicePlanForm
      v-if="isInvoicePlanOpen"
      :invoice-plan-uuid="invoicePlanUuid"
      :contract-uuid="contractUuid"
      @close-modal="onCloseInvoicePlanModalForm"
      @refresh-table="onRefreshTable('invoicePlanTable')"
    />
    <ui-card
      header="Sales Orders"
      description="Select the vehicles, the service agreement and the service fees of your sales order."
      class="mb-3 d-block"
    >
      <MuiAlgoliaList
        ref="salesOrderTable"
        :actions="salesOrderTableActions"
        :table-config="salesOrderListConfig"
        :filters="filterByContract(contractUuid)"
        :index="ALGOLIA_INDEXES.fpSalesOrders"
        :empty-message="getNoResultsLabel('sales orders')"
        data-test-id="sales-orders"
        class="p-3"
      />
    </ui-card>
    <ui-card
      header="Service fees overview"
      description="A Service fee is a dedicated fleet service invoiced to the client as an invoice line linked to a billing reference"
      class="mb-3"
    >
      <MuiAlgoliaList
        ref="serviceFeesTable"
        :actions="serviceFeesTableActions"
        :table-config="serviceFeesListConfig"
        :filters="filterByContract(contractUuid)"
        :item-actions="serviceFeesActions"
        :index="ALGOLIA_INDEXES.fpServiceFees"
        :empty-message="getNoResultsLabel('service fees')"
        data-test-id="service-fees"
        class="p-3"
      />
    </ui-card>
    <ServiceFeesModal
      v-if="isServiceFeesModalOpen"
      :service-fee="serviceFee"
      @close-modal="onCloseServiceFeesModal"
      @refresh-table="onRefreshTable('serviceFeesTable')"
    />
    <SalesOrderForm
      v-if="isSalesOrderModalOpen"
      @close-modal="onCloseSalesOrderForm"
      @refresh-table="onRefreshTable('salesOrderTable')"
    />
  </div>
</template>
