var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ContractDetailView" },
    [
      _c("h1", { staticClass: "mb-3" }, [_vm._v(" Contract details ")]),
      _c(
        "ui-card",
        { staticClass: "mb-3 d-block", attrs: { header: "Invoice Plans" } },
        [
          _c("MuiAlgoliaList", {
            ref: "invoicePlanTable",
            staticClass: "p-3",
            attrs: {
              actions: _vm.invoicePlanTableActions,
              "table-config": _vm.invoicePlanAlgoliaConfig,
              filters: _vm.filterByContract(_vm.contractUuid),
              "item-actions": _vm.invoicePlansActions,
              index: _vm.ALGOLIA_INDEXES.fpInvoicePlans,
              "empty-message": _vm.getNoResultsLabel("invoice plans"),
            },
          }),
        ],
        1
      ),
      _vm.isInvoicePlanOpen
        ? _c("InvoicePlanForm", {
            attrs: {
              "invoice-plan-uuid": _vm.invoicePlanUuid,
              "contract-uuid": _vm.contractUuid,
            },
            on: {
              "close-modal": _vm.onCloseInvoicePlanModalForm,
              "refresh-table": function ($event) {
                return _vm.onRefreshTable("invoicePlanTable")
              },
            },
          })
        : _vm._e(),
      _c(
        "ui-card",
        {
          staticClass: "mb-3 d-block",
          attrs: {
            header: "Sales Orders",
            description:
              "Select the vehicles, the service agreement and the service fees of your sales order.",
          },
        },
        [
          _c("MuiAlgoliaList", {
            ref: "salesOrderTable",
            staticClass: "p-3",
            attrs: {
              actions: _vm.salesOrderTableActions,
              "table-config": _vm.salesOrderListConfig,
              filters: _vm.filterByContract(_vm.contractUuid),
              index: _vm.ALGOLIA_INDEXES.fpSalesOrders,
              "empty-message": _vm.getNoResultsLabel("sales orders"),
              "data-test-id": "sales-orders",
            },
          }),
        ],
        1
      ),
      _c(
        "ui-card",
        {
          staticClass: "mb-3",
          attrs: {
            header: "Service fees overview",
            description:
              "A Service fee is a dedicated fleet service invoiced to the client as an invoice line linked to a billing reference",
          },
        },
        [
          _c("MuiAlgoliaList", {
            ref: "serviceFeesTable",
            staticClass: "p-3",
            attrs: {
              actions: _vm.serviceFeesTableActions,
              "table-config": _vm.serviceFeesListConfig,
              filters: _vm.filterByContract(_vm.contractUuid),
              "item-actions": _vm.serviceFeesActions,
              index: _vm.ALGOLIA_INDEXES.fpServiceFees,
              "empty-message": _vm.getNoResultsLabel("service fees"),
              "data-test-id": "service-fees",
            },
          }),
        ],
        1
      ),
      _vm.isServiceFeesModalOpen
        ? _c("ServiceFeesModal", {
            attrs: { "service-fee": _vm.serviceFee },
            on: {
              "close-modal": _vm.onCloseServiceFeesModal,
              "refresh-table": function ($event) {
                return _vm.onRefreshTable("serviceFeesTable")
              },
            },
          })
        : _vm._e(),
      _vm.isSalesOrderModalOpen
        ? _c("SalesOrderForm", {
            on: {
              "close-modal": _vm.onCloseSalesOrderForm,
              "refresh-table": function ($event) {
                return _vm.onRefreshTable("salesOrderTable")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }