var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-form",
    { on: { submitform: _vm.onSubmit } },
    [
      _c("MuiValidationWrapper", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ areAllValid }) {
              return [
                _c(
                  "GenericModalComponent",
                  _vm._b(
                    {
                      attrs: {
                        header: { isClosable: true },
                        title: _vm.serviceFee
                          ? "Edit service fee"
                          : "Add service fee",
                        "is-open": "",
                      },
                      on: { "modal-closed": _vm.closeModal },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "alerts",
                            fn: function () {
                              return [
                                _vm.isRefundWarningVisible
                                  ? _c(
                                      "ui-alert",
                                      {
                                        staticClass: "d-block mb-5",
                                        attrs: {
                                          color: _vm.COLORS.warning,
                                          icon: _vm.ICONS.alertFull,
                                          dismissible: "",
                                        },
                                        on: {
                                          dismissAlert: function ($event) {
                                            _vm.isRefundWarningVisible = false
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          ' You\'ve selected "Refund". Make sure this is the action you want to do '
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("div", [
                                  _c("p", [
                                    _vm._v(
                                      " Configure the service invoice lines as they are going to appear in the invoice "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "row mt-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6 mb-4" },
                                      [
                                        _c("MuiSelect", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: {
                                                isRequired: true,
                                              },
                                              expression:
                                                "{\n                    isRequired: true,\n                  }",
                                            },
                                          ],
                                          staticClass: "w-100",
                                          attrs: {
                                            options: _vm.servicesList,
                                            name: "serviceType",
                                            "option-label": "label",
                                            "option-value": "value",
                                            label: "Service type*",
                                            placeholder:
                                              "Select a service type",
                                          },
                                          model: {
                                            value: _vm.inputs.serviceType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inputs,
                                                "serviceType",
                                                $$v
                                              )
                                            },
                                            expression: "inputs.serviceType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6 mb-4" },
                                      [
                                        _c("MuiInputText", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate.blur",
                                              value: {
                                                isRequired: true,
                                              },
                                              expression:
                                                "{\n                    isRequired: true,\n                  }",
                                              modifiers: { blur: true },
                                            },
                                          ],
                                          staticClass: "w-100",
                                          attrs: {
                                            label: "Service name*",
                                            name: "name",
                                            placeholder:
                                              "Enter a service name*",
                                          },
                                          model: {
                                            value: _vm.inputs.name,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.inputs, "name", $$v)
                                            },
                                            expression: "inputs.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6 mb-4" },
                                      [
                                        _c("MuiDatePicker", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: {
                                                isRequired: true,
                                              },
                                              expression:
                                                "{\n                    isRequired: true,\n                  }",
                                            },
                                          ],
                                          staticClass: "w-100",
                                          attrs: {
                                            date: _vm.initActiveStartDate,
                                            size: _vm.SIZES.small,
                                            label: "Active start date*",
                                            skiptime: "",
                                            "data-test-id":
                                              "service_fees-start_date",
                                          },
                                          on: {
                                            "update:date": _vm.formatStartDate,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6 mb-4" },
                                      [
                                        _c("MuiDatePicker", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: {
                                                isRequired: true,
                                              },
                                              expression:
                                                "{\n                    isRequired: true,\n                  }",
                                            },
                                          ],
                                          staticClass: "w-100",
                                          attrs: {
                                            date: _vm.initActiveEndDate,
                                            size: _vm.SIZES.small,
                                            label: "Active end date*",
                                            skiptime: "",
                                            "data-test-id":
                                              "service_fees-end_date",
                                          },
                                          on: {
                                            "update:date": _vm.formatEndDate,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 mb-4" },
                                      [
                                        _c("MuiTextarea", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate.blur",
                                              value: {
                                                isRequired: true,
                                              },
                                              expression:
                                                "{\n                    isRequired: true,\n                  }",
                                              modifiers: { blur: true },
                                            },
                                          ],
                                          attrs: {
                                            label: "Description*",
                                            name: "description",
                                            placeholder: "Enter a description",
                                          },
                                          model: {
                                            value: _vm.inputs.description,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inputs,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression: "inputs.description",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 mb-4" },
                                      [
                                        _c("MuiSelect", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: {
                                                isRequired: true,
                                              },
                                              expression:
                                                "{\n                    isRequired: true,\n                  }",
                                            },
                                          ],
                                          staticClass: "w-100",
                                          attrs: {
                                            options: _vm.CHARGES,
                                            name: "affectedVehicles",
                                            label: "Affected vehicles*",
                                            placeholder:
                                              "Select affected vehicles",
                                          },
                                          model: {
                                            value: _vm.inputs.affectedVehicles,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inputs,
                                                "affectedVehicles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "inputs.affectedVehicles",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 mb-4" },
                                      [
                                        _c("MuiAlgoliaSelect", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: {
                                                isRequired: true,
                                              },
                                              expression:
                                                "{\n                    isRequired: true,\n                  }",
                                            },
                                          ],
                                          staticClass: "w-100",
                                          attrs: {
                                            title: ({ billing_reference }) =>
                                              billing_reference,
                                            filters: _vm.filterByContract(
                                              _vm.contractUuid
                                            ),
                                            index:
                                              _vm.ALGOLIA_INDEXES
                                                .fpInvoicePlans,
                                            label: "Invoice plan*",
                                            name: "invoicePlanUuid",
                                            "path-value": "uuid",
                                          },
                                          model: {
                                            value: _vm.inputs.invoicePlanUuid,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inputs,
                                                "invoicePlanUuid",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "inputs.invoicePlanUuid",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 mb-4" },
                                      [
                                        _vm.invoiceDocuments.length &&
                                        !_vm.isRefund
                                          ? _c("DocumentSelector", {
                                              attrs: {
                                                "invoice-documents":
                                                  _vm.invoiceDocuments,
                                                label: "Document",
                                              },
                                              model: {
                                                value:
                                                  _vm.inputs.invoiceDocument,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.inputs,
                                                    "invoiceDocument",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "inputs.invoiceDocument",
                                              },
                                            })
                                          : _vm.invoiceDocuments.length &&
                                            _vm.isRefund
                                          ? _c("DocumentSelector", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    isRequired: true,
                                                  },
                                                  expression:
                                                    "{\n                    isRequired: true,\n                  }",
                                                },
                                              ],
                                              attrs: {
                                                "invoice-documents":
                                                  _vm.invoiceDocuments,
                                                label: "Document*",
                                              },
                                              model: {
                                                value:
                                                  _vm.inputs.invoiceDocument,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.inputs,
                                                    "invoiceDocument",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "inputs.invoiceDocument",
                                              },
                                            })
                                          : _c("MuiInputText", {
                                              staticClass: "w-100",
                                              attrs: {
                                                name: "invoiceDocument",
                                                label: "Document",
                                                placeholder:
                                                  "Select or add a document",
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.inputs.invoiceDocument,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.inputs,
                                                    "invoiceDocument",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "inputs.invoiceDocument",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _vm.invoicePlanUuidIfRefund
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "col-12 mb-4 relative",
                                          },
                                          [
                                            _vm.issuedInvoicesLoading
                                              ? _c("ui-loader", {
                                                  attrs: { absolute: "" },
                                                })
                                              : _vm._e(),
                                            _c("MuiSelect", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    isRequired: true,
                                                  },
                                                  expression:
                                                    "{\n                    isRequired: true,\n                  }",
                                                },
                                              ],
                                              ref: "relatedInvoice",
                                              staticClass: "w-100",
                                              attrs: {
                                                options: _vm.map(
                                                  _vm.issuedInvoices,
                                                  (issuedInvoice) => ({
                                                    label: `${
                                                      issuedInvoice.reference
                                                    } - ${_vm.formatRelatedInvoiceDate(
                                                      issuedInvoice.date
                                                    )}`,
                                                    value: issuedInvoice.uuid,
                                                  })
                                                ),
                                                label: "Related invoice*",
                                                placeholder:
                                                  "Select related invoice",
                                                name: "relatedInvoiceUuid",
                                              },
                                              model: {
                                                value:
                                                  _vm.inputs.relatedInvoiceUuid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.inputs,
                                                    "relatedInvoiceUuid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "inputs.relatedInvoiceUuid",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c("MuiSelect", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: {
                                                isRequired: true,
                                              },
                                              expression:
                                                "{\n                    isRequired: true,\n                  }",
                                            },
                                          ],
                                          staticClass: "w-100 mb-4",
                                          attrs: {
                                            options: _vm.filteredPeriods,
                                            name: "periodType",
                                            label: "Recurrence*",
                                            placeholder: "Select a recurrence",
                                          },
                                          model: {
                                            value: _vm.inputs.periodType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inputs,
                                                "periodType",
                                                $$v
                                              )
                                            },
                                            expression: "inputs.periodType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12" },
                                      [
                                        _vm.isAffectingToASalesOrder
                                          ? _c("MuiAlgoliaSelect", {
                                              staticClass: "w-100 mb-4",
                                              attrs: {
                                                title: ({ uuid }) =>
                                                  _vm.formatUuid(uuid),
                                                filters: _vm.filterByContract(
                                                  _vm.contractUuid
                                                ),
                                                index:
                                                  _vm.ALGOLIA_INDEXES
                                                    .fpSalesOrders,
                                                label: "Sales order",
                                                name: "salesOrderUuid",
                                                "path-value": "uuid",
                                                placeholder:
                                                  "Select sales order",
                                              },
                                              model: {
                                                value:
                                                  _vm.inputs.salesOrderUuid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.inputs,
                                                    "salesOrderUuid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "inputs.salesOrderUuid",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.isPrivateUse
                                          ? [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "d-block mb-2 emobg-body-small emobg-font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " Select if net amount is fixed or percentage "
                                                  ),
                                                ]
                                              ),
                                              _c("ui-radio", {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  value: _vm.isPercentage,
                                                  option: true,
                                                  name: "netPriceTypeOpt2",
                                                  caption:
                                                    "% based on private use",
                                                  "data-test-id":
                                                    "radio-percentage",
                                                },
                                                on: {
                                                  changevalue: () =>
                                                    (_vm.isPercentage = true),
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        _vm.showUnitsField
                                          ? _c(
                                              "div",
                                              { staticClass: "mb-4" },
                                              [
                                                _c("MuiInputText", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName:
                                                        "v-validate.blur",
                                                      value: {
                                                        isRequired: true,
                                                        isPositiveInteger: true,
                                                      },
                                                      expression:
                                                        "{\n                      isRequired: true,\n                      isPositiveInteger: true,\n                    }",
                                                      modifiers: { blur: true },
                                                    },
                                                  ],
                                                  attrs: {
                                                    name: "units",
                                                    label: "Units*",
                                                    placeholder:
                                                      "Enter units affected",
                                                  },
                                                  model: {
                                                    value: _vm.inputs.units,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.inputs,
                                                        "units",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression: "inputs.units",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-6" },
                                            [
                                              _vm.isPercentage &&
                                              !_vm.isNonRevenue
                                                ? _c("MuiInputText", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName:
                                                          "v-validate.blur",
                                                        value: {
                                                          isRequired: true,
                                                          isPattern:
                                                            _vm.isNumeric,
                                                        },
                                                        expression:
                                                          "{\n                        isRequired: true,\n                        isPattern: isNumeric,\n                      }",
                                                        modifiers: {
                                                          blur: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      name: "percentage",
                                                      label: "Value*",
                                                      placeholder:
                                                        "Management fee %",
                                                      "data-test-id":
                                                        "percentage",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inputs.percentage,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.inputs,
                                                          "percentage",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "inputs.percentage",
                                                    },
                                                  })
                                                : _vm._e(),
                                              !_vm.isPercentage &&
                                              !_vm.isNonRevenue
                                                ? _c("MuiInputText", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName:
                                                          "v-validate.blur",
                                                        value: {
                                                          isRequired: true,
                                                          isPattern:
                                                            _vm.isNumeric,
                                                        },
                                                        expression:
                                                          "{\n                        isRequired: true,\n                        isPattern: isNumeric,\n                      }",
                                                        modifiers: {
                                                          blur: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      name: "amount",
                                                      "data-test-id": "amount",
                                                      label: "Net amount*",
                                                      placeholder:
                                                        "Net amount per unit",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.netAmountAddDecimal,
                                                    },
                                                    model: {
                                                      value: _vm.inputs.amount,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.inputs,
                                                          "amount",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "inputs.amount",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-6" },
                                            [
                                              !_vm.isNonRevenue
                                                ? _c("MuiInputText", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName:
                                                          "v-validate.blur",
                                                        value: {
                                                          isRequired: true,
                                                          isPattern:
                                                            _vm.isNumeric,
                                                        },
                                                        expression:
                                                          "{\n                        isRequired: true,\n                        isPattern: isNumeric,\n                      }",
                                                        modifiers: {
                                                          blur: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      name: "vat",
                                                      label: "VAT*",
                                                      placeholder: "VAT %",
                                                    },
                                                    model: {
                                                      value: _vm.inputs.vat,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.inputs,
                                                          "vat",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression: "inputs.vat",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-content-center",
                                  },
                                  [
                                    _c("CancelButton", {
                                      attrs: {
                                        "data-test-id":
                                          "servicefee-modal-cancel-button",
                                      },
                                      on: { click: _vm.closeModal },
                                    }),
                                    _c(
                                      "SaveButtonDebounced",
                                      {
                                        attrs: {
                                          disabled: !areAllValid,
                                          type: _vm.BUTTON_TYPES.submit,
                                          narrow: "",
                                          "data-test-id":
                                            "servicefee-modal-save-button",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.serviceFee ? "Save" : "Add"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    "GenericModalComponent",
                    _vm.$attrs,
                    false
                  )
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }