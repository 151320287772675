var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiSelect",
    {
      ref: "documentSelector",
      staticClass: "w-100",
      attrs: {
        options: _vm.invoiceDocuments,
        size: _vm.SIZES.medium,
        searchbox: { threshold: -1 },
        label: _vm.label,
        normalize: false,
        name: "invoiceDocument",
        placeholder: "Select or add document",
        "data-test-id": "document-selector",
      },
      on: {
        change: function ($event) {
          return _vm.$emit("change", _vm.invoiceDocument)
        },
      },
      model: {
        value: _vm.invoiceDocument,
        callback: function ($$v) {
          _vm.invoiceDocument = $$v
        },
        expression: "invoiceDocument",
      },
    },
    [
      _c("template", { slot: "list-actions" }, [
        _vm.get(_vm.$refs, "documentSelector.searchboxText", "").length
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center emobg-background-color-white px-2",
              },
              [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.get(_vm.$refs, "documentSelector.searchboxText", "")
                    )
                  ),
                ]),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      face: _vm.FACES.text,
                      size: _vm.SIZES.medium,
                      color: _vm.COLORS.primary,
                      square: "",
                      "data-test-id": "document-selector-add",
                    },
                    on: { clickbutton: _vm.addInvoiceDocument },
                  },
                  [_vm._v(" Add ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }